<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Link</Heading>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Display</Heading>
							<Paragraph>By default, a link will take up 100% width of the container it is in. To change this, add display="inline".</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="3" align="left">
							<Stack direction="column" align="left">
								<Stage><Link text="Block Link (Default)" url="/navigation/link" /></Stage>
							</Stack>
							<Stack direction="column" align="left" space="2">
								<Stage>
									<Stack width="100%" space="2">
										<Link text="Inline Link" url="/navigation/link" display="inline" />
										<Paragraph margin="none">|</Paragraph>
										<Link text="Inline Link" url="/navigation/link" display="inline" />
									</Stack>
								</Stage>
							</Stack>
							<Stack direction="column" align="left" space="2">
								<Stage><Paragraph> If an <Link text="inline link" url="/navigation/link" display="inline" /> is in the middle of a paragraph, it will take on the paragraph's styles.</Paragraph></Stage>
							</Stack>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Custom</Heading>
							<Paragraph>Remove all props except for url to create a custom link. Place any items inside the empty component to make them clickable.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" align="left" space="2">
							<Stage>
								<Link url="/navigation/link">
									<Stack align="center" space="1">
										<Icon icon="favorite" size="medium" background="primary" color="light" />
										<Heading size="4" margin="none">Custom Link</Heading>
									</Stack>
								</Link>
							</Stage>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Colors</Heading>
							<Paragraph>There are three color variations for link colors that can be applied using the color prop.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Stack>
								<Stage>
									<Link text="Primary Link" color="primary" url="/navigation/link" />
								</Stage>
							</Stack>
							<Stack>
								<Stage>
									<Link text="Secondary Link" color="secondary" url="/navigation/link" />
								</Stage>
							</Stack>
							<Stack>
								<Theme ar dl ed sd ds>
									<Stage>
										<Link text="Tertiary Link" color="tertiary" url="/navigation/link" />
									</Stage>
								</Theme>
							</Stack>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="6">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Sizes</Heading>
							<Paragraph>There are two size variations for links that can be set using the size prop.</Paragraph>
						</Stack>
					</CardSection>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Stack>
								<Stage>
									<Link text="Large Link" size="large" url="/navigation/link" />
								</Stage>
							</Stack>
							<Stack>
								<Stage>
									<Link text="Default Link" url="/navigation/link" />
								</Stage>
							</Stack>
							<Stack>
								<Stage>
									<Link text="Small Link" size="small" url="/navigation/link" />
								</Stage>
							</Stack>
						</Stack>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12">
				<Card>
					<CardSection>
						<Stack direction="column" space="2" align="left">
							<Heading size="5">Link Properties</Heading>
						</Stack>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Property</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Type</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Options</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Explanation</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">text</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The text shown inside the button.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">url</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Provides a url for the link.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">icon</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">-</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the icon before the text. Can be any icon from material icon list.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">color</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										primary <br/>
										secondary <br/>
										<Theme ar dl ed sd ds>
											tertiary <br/>
										</Theme>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">The base color that applies to the link component.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">size</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">
										small <br/>
										large <br/>
									</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Defines the size of the link text.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">display</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">inline</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Sets the css display property of the link to inline.</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Property</Paragraph></Stack>
									<Paragraph margin="none">type</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Type</Paragraph></Stack>
									<Paragraph margin="none">String</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Options</Paragraph></Stack>
									<Paragraph margin="none">external</Paragraph>
								</TableCell>
								<TableCell>
									<Stack visibility="show hide@sm"><Paragraph margin="none" bold>Explanation</Paragraph></Stack>
									<Paragraph margin="none">Passes the type to the link.</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
						
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		this.stageTheme(this.theme);
  },
}
</script>

<style lang="scss" scoped>
</style>
